body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.boxesgrid {
  display: flex;
  flex-wrap: wrap;
  height: 1000px;
  width: 1000px;
  background-image: url('assets/img/sections/pride-march.jpg');
}
.boxrow {
  display: flex;
  flex-direction: row-reverse;
}
.colorbox {
  width: 100px;
  height: 100px;
  background-color: dimgray;
  float: left;
  border: 0px solid black;
}
.selected {
  width: 100px;
  height: 100px;
  float: left;
  border: 3px solid black;
}