.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.index-background {
  background-color: #B2AFAB;
  /* background-position: center center; */
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
.index-title {
  font-size: 8em;
  font-weight: 700;
  margin: 0;
  color: #000000;
  background: #fbf8ec;
  background: -moz-linear-gradient(top, #000000 35%, #4e6773 100%);
  background: -webkit-linear-gradient(top, #000000 35%, #4e6773 100%);
  background: linear-gradient(to bottom, #000000 35%, #4e6773 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
}
.index-background .motto {
  color: #FFFFFF;
  text-align: center;
  z-index: 2;
  position: relative; 
}
.page-header {
  background-position: inherit;
}
.presentation-subtitle {
  font-size: 2em;
  font-weight: 500;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  -webkit-text-fill-color: #000000;
}
.imagezoom {
  transition: transform .2s; /* Animation */
  width: 100%;
  height: 100%;
  position: relative;
}

.imagezoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.card:hover {
  z-index: 99 !important;
}
.contactme {
  font-size: 2em;
  font-weight: 300;
  
}
#gitbutton {
  padding-right: 20px;
}
.skill-summary {
  font-size: 1.5em;
  font-weight: 500;
}